"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getMetricsFactoryForTesting = getMetricsFactoryForTesting;
exports.initErrorMetrics = initErrorMetrics;
exports.resetErrorTrackingForTesting = resetErrorTrackingForTesting;
var _MetricsFactory = require("../MetricsFactory");
var _getGlobal = require("../getGlobal");
let evtTarget = window;
let factory;
function setErrorMetricsSetupComplete(newValue) {
  (0, _getGlobal.getHubSpot)().__errorMetricsSetupComplete = newValue;
}
function getErrorMetricsSetupComplete() {
  return (0, _getGlobal.getHubSpot)().__errorMetricsSetupComplete;
}
function initErrorMetrics(customTarget) {
  // for testing - actually dispatching errors to the window during tests
  // causes the tests to fail
  evtTarget = customTarget || evtTarget;
  if (factory || getErrorMetricsSetupComplete()) {
    return;
  }

  // Only setup listeners once, otherwise we get multiple errors per asynchronously loaded bundle (Navigation, Zorse, etc).
  setErrorMetricsSetupComplete(true);

  // Need to construct a factory directly to avoid a circular dependency in
  // createMetricsFactory. Do not copy this into app/library code.
  // Final metric name: *.frontend.js.errors.count
  factory = new _MetricsFactory.MetricsFactory('js', {});

  // We need to initialize per-session metrics to 0, otherwise the metric
  // will not be reported if no errors occur in a session.
  factory[_MetricsFactory.$SessionCounter]('errors-per-session').increment(0);
  try {
    evtTarget.addEventListener('unhandledrejection', onUnhandledPromiseRejection);
    evtTarget.addEventListener('rejectionhandled', onHandledPromiseRejection);
    evtTarget.addEventListener('ravenSuccess', onRavenRequestSuccess);
    evtTarget.addEventListener('ravenCaptureIgnored', onRavenCaptureIgnored);
  } catch (__err) {
    // ignore, this is an unrecoverable failure
  }
}
function getMetricsFactoryForTesting() {
  return factory;
}
function resetErrorTrackingForTesting() {
  try {
    factory = undefined;
    if ((0, _getGlobal.getHubSpot)()) delete (0, _getGlobal.getHubSpot)().__errorMetricsSetupComplete;
  } catch (__err) {
    // ignore, this is an unrecoverable failure
  }
}
const EXTENSION_REGEX = /@<inline>|moz-extension:\/\/|chrome-extension:\/\/|safari-web-extension:\/\/|safari-extension:\/\//;
function isBrowserExtensionError(errObj) {
  if (errObj && errObj.stack && errObj.stack.match(EXTENSION_REGEX)) {
    if (factory) {
      factory.counter('browser-extension-errors').increment();
    }
    return true;
  }
  return false;
}
function onUnhandledPromiseRejection(evt) {
  if (!factory) return;
  if (evt.reason && isBrowserExtensionError(evt.reason)) {
    return;
  }
  factory.counter('unhandled-promise-rejection').increment();
}
function onHandledPromiseRejection() {
  if (!factory) return;
  factory.counter('handled-promise-rejection').increment();
}
function onRavenRequestSuccess(errEvt) {
  if (!factory) return;
  const data = 'data' in errEvt ? errEvt.data : undefined;

  // level may be undefined, that indicates a error-level report
  if (data && (data.level === 'error' || data.level == null)) {
    factory.counter('errors').increment();
    factory[_MetricsFactory.$SessionCounter]('errors-per-session').increment();
  }
}
function onRavenCaptureIgnored(evt) {
  if (!factory) return;

  // level always defined in ravenCaptureIgnored event
  if ('level' in evt && evt.level === 'error') {
    factory.counter('error-reports-ignored-by-configuration').increment();
  }
}